import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-gtag';
import { EuiSpacer } from '@elastic/eui';
import NavButtons from 'components/Outdoor_Cameras/IN-9020_HD/NavButtons';
import CompareOutdoor from 'components/Products/Compare/CompareOutdoorFlyout';
import SmartCards from 'components/Products/Flyouts/Cards/9008Cards';
import TableFeatures from 'components/Outdoor_Cameras/IN-9010_HD/TableFeatures';
import OutdoorData from 'components/Products/Flyouts/Graphs/OutdoorGraphsFlyout';
import RadarGraphCard from 'components/Products/RadarGraphs/in9020-radargraph-grid';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "IN-9020 Full HD",
  "path": "/Outdoor_Cameras/IN-9020_HD/",
  "dateChanged": "2017-12-12",
  "author": "Mike Polinowski",
  "excerpt": "INSTAR IN-9020 Full HD Outdoor IP Camera",
  "image": "../P_SearchThumb_IN-9020HD.png",
  "social": "/images/Search/P_SearchThumb_IN-9020HD.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-IN-9020HD_white.webp",
  "chapter": "Outdoor Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='IN-9020 Full HD' dateChanged='2017-12-12' author='Mike Polinowski' tag='INSTAR IP Camera' description='INSTAR IN-9020 Full HD Outdoor IP Camera' image='/images/Search/P_SearchThumb_IN-9020HD.png' twitter='/images/Search/P_SearchThumb_IN-9020HD.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Aussenkameras/IN-9020_HD/' locationFR='/fr/Outdoor_Cameras/IN-9020_HD/' crumbLabel="IN-9020" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "in-9020-full-hd",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#in-9020-full-hd",
        "aria-label": "in 9020 full hd permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IN-9020 Full HD`}</h1>
    <CompareOutdoor mdxType="CompareOutdoor" />
    <h2 {...{
      "id": "product-overview",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#product-overview",
        "aria-label": "product overview permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Product Overview`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/5af46821dd7fe2ed0f93a66e77f63f94/573d3/IN-9020_Overview_width1650.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50.43478260869565%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsTAAALEwEAmpwYAAABqUlEQVQoz2WS3WujQBTF+//DUtiHQh/6tix9LS2FQh+WXQjd7VraNKUxxjQfxkRjTIyajDozZ7lXJyTbAfGOHn733I8TrTXooaO0RllWKKuK4/p7/c9o6C1EgZ0QyPMttrsdpNasp3NyKBZCYBEtMRhOEEYrLOM14tX6CEZRWZbwPA9O/4NjBaBSqgYeOozjAPMgQLfrwHpqY+qHmM0DaF2LpVKQGkizFGHow5+FiKIlJ5HGoYGR9YxLEAiCBaynFzxaL5xANdll05I0zSGlxjrZwHWHSLOcocTal7xJM0ilGejPAvQcF2/vPY6berk0SppvBSopEa8SDAYjNnAEpDOfh2i/drCIYozHU/TsPopmOGjaYoCk67sfiJYr2LYDb+rXoyOgKXk4muC53UHPGeDRemZnshmCAVJMk334Y7GedJ23Lpe+d2iASbbF2fkFvn2/ROv3XyTJ5mgdCGom+eNnC19Ov+L27h6242InCnbPDk12CXDmX60HdN5tFEXBsD3wYDVGYw9X1zesn3hT1sj/91A1PcpFwevBACk/AQ/v1E80q09aAv4DTK397SeDbTAAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5af46821dd7fe2ed0f93a66e77f63f94/e4706/IN-9020_Overview_width1650.avif 230w", "/en/static/5af46821dd7fe2ed0f93a66e77f63f94/d1af7/IN-9020_Overview_width1650.avif 460w", "/en/static/5af46821dd7fe2ed0f93a66e77f63f94/7f308/IN-9020_Overview_width1650.avif 920w", "/en/static/5af46821dd7fe2ed0f93a66e77f63f94/e1c99/IN-9020_Overview_width1650.avif 1380w", "/en/static/5af46821dd7fe2ed0f93a66e77f63f94/e02f7/IN-9020_Overview_width1650.avif 1650w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/5af46821dd7fe2ed0f93a66e77f63f94/a0b58/IN-9020_Overview_width1650.webp 230w", "/en/static/5af46821dd7fe2ed0f93a66e77f63f94/bc10c/IN-9020_Overview_width1650.webp 460w", "/en/static/5af46821dd7fe2ed0f93a66e77f63f94/966d8/IN-9020_Overview_width1650.webp 920w", "/en/static/5af46821dd7fe2ed0f93a66e77f63f94/445df/IN-9020_Overview_width1650.webp 1380w", "/en/static/5af46821dd7fe2ed0f93a66e77f63f94/44758/IN-9020_Overview_width1650.webp 1650w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5af46821dd7fe2ed0f93a66e77f63f94/81c8e/IN-9020_Overview_width1650.png 230w", "/en/static/5af46821dd7fe2ed0f93a66e77f63f94/08a84/IN-9020_Overview_width1650.png 460w", "/en/static/5af46821dd7fe2ed0f93a66e77f63f94/c0255/IN-9020_Overview_width1650.png 920w", "/en/static/5af46821dd7fe2ed0f93a66e77f63f94/b1001/IN-9020_Overview_width1650.png 1380w", "/en/static/5af46821dd7fe2ed0f93a66e77f63f94/573d3/IN-9020_Overview_width1650.png 1650w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/5af46821dd7fe2ed0f93a66e77f63f94/c0255/IN-9020_Overview_width1650.png",
              "alt": "INSTAR IN-9020 Full HD",
              "title": "INSTAR IN-9020 Full HD",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <TableFeatures mdxType="TableFeatures" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`The IN-9020 Full HD is the newest Full HD PIR outdoor pan / tilt / zoom IP camera from INSTAR.With a built - in state - of -the - art Panasonic passive infrared(PIR) motion sensor and four separately configurable alarm areas that can be linked together, the camera prevents false alarms and notifies you only when a real alarm has been triggered.`}</p>
    <p>{`Equipped with one of the latest Panasonic Full HD CMOS image sensors, which features low noise, high speed and new Wide Dynamic Range(WDR) functionality, the IN-9020 is able to achieve the best image quality in daylight as well as clear image in night vision mode with the help of its high - power LEDs.Hence, the IN - 9020 is able to deliver accurate motion detection and alarm notification.`}</p>
    <OutdoorData mdxType="OutdoorData" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`The design of this outdoor camera was completely new developed in order to fulfil the wishes of our customers.Therefore, it is now very easy to pull the connection cable through the wall as it is much smaller, you won´ t have to drill huge holes into your wall anymore.Furthermore, thanks to the new concept design, a crystal clear vision, day and night, is ensured, because the camera does not require a dome dome anymore.`}</p>
    <h3 {...{
      "id": "hardware",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#hardware",
        "aria-label": "hardware permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Hardware`}</h3>
    <ul>
      <li parentName="ul">{`Full HD WDR Panasonic CMOS sensor / resolution 1920 x 1080px`}</li>
      <li parentName="ul">{`Varied focal autofocus lense: 2.8mm ~ 12mm ( 36 ~ 120º diagonal field of view)`}</li>
      <li parentName="ul">{`Integrated Panasonic Pyroelectric Sensor - PIR Range up to 12m`}</li>
      <li parentName="ul">{`SD Card with max. 128GB (16GB incl.) for alarm, manual and continuous recording`}</li>
      <li parentName="ul">{`Integrated microphone for sound recording`}</li>
      <li parentName="ul">{`Alarm-out Relay - switches external alarm devices (for example a Siren)`}</li>
    </ul>
    <h3 {...{
      "id": "network",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#network",
        "aria-label": "network permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Network`}</h3>
    <ul>
      <li parentName="ul">{`Free point-2-point (P2P) connectivity with InstarVision, works with IPv6`}</li>
      <li parentName="ul">{`Free DDNS address to connect to your camera via the internet`}</li>
      <li parentName="ul">{`Optional upload & management of alarm videos in the INSTAR Cloud`}</li>
      <li parentName="ul">{`Upload alarm images / videos to an FTP server (FTP or secure FTPS)`}</li>
      <li parentName="ul">{`2.4Ghz WiFi with IEEE 802.11b/g/n and WPA/WPA2 encryption (only WiFi model)`}</li>
      <li parentName="ul">{`Access the WebUI via HTTPS with secure TLS/SSL encryption`}</li>
    </ul>
    <h3 {...{
      "id": "software",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#software",
        "aria-label": "software permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Software`}</h3>
    <ul>
      <li parentName="ul">{`Privacy Mask areas for privacy concerns`}</li>
      <li parentName="ul">{`Define four Motion Detection Areas for software motion detection and link with PIR`}</li>
      <li parentName="ul">{`Supports motion, heat and noise detection`}</li>
      <li parentName="ul">{`Alarm videos pre-triggered 3s before the trigger event`}</li>
      <li parentName="ul">{`Alarm emails and push (text) notifications in case of an alarm trigger`}</li>
      <li parentName="ul">{`Send alarm triggers to third party devices such as home automations`}</li>
      <li parentName="ul">{`15s alarm video / scheduled / manual continuous recording on SD`}</li>
      <li parentName="ul">{`Supports stream protocols like RTSP, RTP, RTMP, MJPEG...`}</li>
      <li parentName="ul">{`Interval snapshots for website integration or time lapse videos`}</li>
      <li parentName="ul">{`Free `}<Link to="/Software/Android/" mdxType="Link">{`Android`}</Link>{`, `}<Link to="/Software/iOS/" mdxType="Link">{`iPhone`}</Link>{` and `}<Link to="/Software/Windows/" mdxType="Link">{`Windows Phone`}</Link>{` App`}</li>
      <li parentName="ul">
        <Link to="/Web_User_Interface/1080p_Series/" mdxType="Link">Web user interface</Link> allows an easy configuration and camera control through your default web browser (Chrome, Safari, Firefox, Internet Explorer)
      </li>
      <li parentName="ul">
        <Link to="/Web_User_Interface/1080p_Series/System/User/" mdxType="Link">Multi user management</Link> and simultaneous login for 3 users
      </li>
      <li parentName="ul">
        <Link to="/Web_User_Interface/1080p_Series/Alarm/Areas/" mdxType="Link">Software-based motion</Link> and <Link to="/Web_User_Interface/1080p_Series/Alarm/Actions/" mdxType="Link">audio</Link> detection and <Link to="/Motion_Detection/Alarm_Notification/" mdxType="Link"> email notification</Link> with attached snapshots
      </li>
      <li parentName="ul">{`Record videos directly to an `}<Link to="/Motion_Detection/SD_Card_Access/" mdxType="Link">{`Internal SD Card`}</Link></li>
    </ul>
    <EuiSpacer mdxType="EuiSpacer" />
    <SmartCards mdxType="SmartCards" />
    <EuiSpacer mdxType="EuiSpacer" />
    
    <p>{`The 1/3 inch WDR sensor is a Panasonic CMOS chip, enabling 1080p HD video at unparalleled low-light sensitivity. Panasonic offers a wide variety of image sensors (`}<OutboundLink href="http://www.semicon.panasonic.co.jp/en/products/imagesensors/surveillance/" mdxType="OutboundLink">{`νMaicovicon®`}</OutboundLink>{`) for security and network cameras, which are based on the industry's most advanced technology and can be applied to security cameras requiring high picture quality with high sensitivity and low noise. The CMOS image sensor, νMaicovicon®, features high picture quality equal to high-speed CCD technologies, high S/N ratio, low noise, and high dynamic range. Even in a dark condition, you can retrieve snapshots with excellent character detection, color reproduction, as well as object detection. Even in scenes with fast-moving objects, the image sensor ensures clear images without motion blur or strain. The frame composite function (WDR) enables high-dynamic range - you can simultaneously resolve dark and bright areas in your video frame.`}</p>
    <RadarGraphCard mdxType="RadarGraphCard" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`This camera is running an embedded Linux operating system and a Webserver which supports TLS / SSL encryption for security and privacy.It provides a built - in , modern HTML5 based Web User Interface(WebUI) with focus on usability and simplicity, supporting all recent devices(desktop, tablet, smartphone) and operating systems(Windows, MacOS, Linux, …).Thanks to the built - in camera setup wizard and the(built-in ) help function, INSTAR users can enjoy a very easy initial set - up of the IP camera.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      